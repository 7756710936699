.orbit {
  position: relative;
  width: 100%;
}

.orbitWrap {
  height: 25em;
  font-size: 1.5em;
  position: relative;
}

.orbitIcon {
  width: 2.2em;
  height: 2.2em;
  object-fit: contain;
}

.ring {
  border: solid 1px rgba(33, 150, 243, 0.8);
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  list-style: none;
  padding: 0;

  li {
    position: absolute;
    top: 45%;
    left: 45%;
    transform: translate(-50%, -50%);
  }
}

.ring0 {
  width: 22em;
  height: 22em;

  li {
    animation: orbit 20s linear infinite;
  }

  @for $i from 1 through 9 {
    li:nth-child(#{$i}) {
      animation-delay: #{$i * -2.22}s;
    }
  }
}

.ring1 {
  width: 17em;
  height: 17em;

  li {
    animation: orbit2 20s linear infinite;
  }

  @for $i from 1 through 6 {
    li:nth-child(#{$i}) {
      animation-delay: #{$i * -3.33}s;
    }
  }
}

.orbitCenter {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 5;
  width: 5.5em;
  height: 5.5em;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.orbitCenterIcon {
  width: 80%;
  border-radius: 40px;
  transition: transform 300ms ease-in-out;

  &:hover {
    transform: scale(1.1);
  }
}

@keyframes orbit {
  from {
    transform: rotate(0deg) translateX(11em) rotate(0deg);
  }
  to {
    transform: rotate(360deg) translateX(11em) rotate(-360deg);
  }
}

@keyframes orbit2 {
  from {
    transform: rotate(0deg) translateX(8.5em) rotate(0deg);
  }
  to {
    transform: rotate(360deg) translateX(8.5em) rotate(-360deg);
  }
}

/* Media queries */
@media (max-width: 1024px) {
  .orbitWrap {
    height: 16em;
  }

  .orbitCenter {
    width: 4em;
    height: 4em;
  }

  .ring0 {
    width: 18em;
    height: 18em;
  }

  .ring1 {
    width: 13em;
    height: 13em;
  }

  @keyframes orbit {
    from {
      transform: rotate(0deg) translateX(9em) rotate(0deg);
    }
    to {
      transform: rotate(360deg) translateX(9em) rotate(-360deg);
    }
  }

  @keyframes orbit2 {
    from {
      transform: rotate(0deg) translateX(6.5em) rotate(0deg);
    }
    to {
      transform: rotate(360deg) translateX(6.5em) rotate(-360deg);
    }
  }
}

@media (max-width: 768px) {
  .orbitWrap {
    height: 28em;
  }

  .orbitCenter {
    width: 4em;
    height: 4em;
  }

  .ring0 {
    width: 18em;
    height: 18em;
  }

  .ring1 {
    width: 13em;
    height: 13em;
  }

  @keyframes orbit {
    from {
      transform: rotate(0deg) translateX(9em) rotate(0deg);
    }
    to {
      transform: rotate(360deg) translateX(9em) rotate(-360deg);
    }
  }

  @keyframes orbit2 {
    from {
      transform: rotate(0deg) translateX(6.5em) rotate(0deg);
    }
    to {
      transform: rotate(360deg) translateX(6.5em) rotate(-360deg);
    }
  }
}

@media (max-width: 425px) {
  .orbitWrap {
    height: 26em;
    width: 320px;
  }

  .orbitCenter {
    width: 3em;
    height: 3em;
  }

  .orbitIcon {
    width: 1.5em;
    height: 1.5em;
  }

  .ring0 {
    width: 300px;
    height: 300px;
  }

  .ring1 {
    width: 220px;
    height: 220px;
  }

  @keyframes orbit {
    from {
      transform: rotate(0deg) translateX(6.2em) rotate(0deg);
    }
    to {
      transform: rotate(360deg) translateX(6.2em) rotate(-360deg);
    }
  }

  @keyframes orbit2 {
    from {
      transform: rotate(0deg) translateX(4.5em) rotate(0deg);
    }
    to {
      transform: rotate(360deg) translateX(4.5em) rotate(-360deg);
    }
  }
}
